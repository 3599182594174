.ShareReq {
    padding: 80px 64px 0;
    margin: 0 auto 0;
    display: flex;
    justify-content: center;
    /* text-align: left; */
    gap: 10%;
}

<<<<<<< HEAD
.notification {
    margin-top: 2px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
  }
  
  .notification.success {
    background-color: #28a745;
    color: white;
  }
  
  .notification.error {
    background-color: #dc3545;
    color: white;
  }
  
=======
>>>>>>> db60ad2de9a176bd07cd2e61e6990ecdf1698d1b
.LC_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.SR_head {
    font-size: 48px;
    font-weight: 600;
}

.LC_imgDiv {
    display: flex;
    justify-content: center;
}

.LC_imgDiv>img {
    width: 500px;
}

.SR_Inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.SR_input {
    border-radius: 20px;
    padding: 14px 16px;
    background-color: #6484961A;
    border: 0.25px solid #2a2f336e;
    font-size: 14px;
    color: #2A2F3399;
    width: 300px;
}

.LC_reqinput {
    height: 48px;
    display: flex;
    align-items: first baseline;
}

.LC_btn {
    background-color: var(--green);
    color: white;
    border: none;
    font-weight: 600;
    width: 160px;
    margin-bottom: 40px;
}


@media only screen and (orientation: portrait) {
    .ShareReq {
        padding: 40px 24px 0;
    }

    .LC_content {
        gap: 32px;
    }

    .SR_head {
        font-size: 24px;
    }

    .LC_imgDiv>img {
        width: 500px;
    }

    .SR_Inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    .SR_input {
        width: 100%;
    }
}