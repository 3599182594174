.AdminDashboard {
    padding: 64px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 179px);
    gap: 56px;
}

.Admindash_DataDiv {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    /* align-items: flex-start; */
}

.admin_servicesHead {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    display: flex;
    gap: 16px;
}
.showServicesBtn{
    cursor: pointer;
    transition: color 200ms ease-in-out;
}
.showServicesBtn:hover{
    color: var(--orange);
}
.showServicesBtn.selected{
    color: var(--orange);
}

.adminCompany_deleteDiv {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.adminCompany_deleteBtn {
    border: 1px solid transparent;
    background-color: #d9534f;
    color: white;
}
.adminBlog_deleteDiv{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 360px;
    width: calc(100% - 40px);
}

.modal-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 32px;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

.modal-button.confirm {
    background-color: #d9534f;
    color: white;
}

.modal-button.cancel {
    background-color: #f0f0f0;
}

.addBlogBtn{
    font-size: 14px;
    border: none;
    background-color: var(--green);
    color: white;
}

.blogsContainer.admin {
    padding: 32px 0px;
}

.blogBox.admin {
    min-height: 550px;
    font-size: 14px;
}

.blogText.admin {
    overflow-y: hidden;
    max-height: 98px;
    min-height: 98px;
}


@media only screen and (orientation: portrait) {
    .AdminDashboard {
        padding: 24px;
    }

    .blogsContainer.admin {
        padding: 32px 0px;
    }
    
    .blogBox.admin {
        min-height: unset;
    }
    
    .blogText.admin {
        max-height: 100px;
        min-height: unset;
    }
}