
.whatsapp-svg {
    animation: bounce 1.5s infinite;
    height: 60px;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  @keyframes opacityAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.85;
    }
    100% {
        opacity: 0.6;
    }
}
  
  .whatsapp-link {
    display: inline-block;
    text-decoration: none;
  }
  
  .whatsapp-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 80vh;
        right: 2vh;
        opacity: 70%;
        animation: opacityAnimation 3s ease-in-out infinite;
  }
  
  .whatsapp-link {
    display: block;
    text-align: center;
  }
  
  .whatsapp-text {
    margin-top: 0px;
    font-size: 10px;
    color: #131212;
    font-weight: bold;
  }
  