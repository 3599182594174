.Blogs {}

.blogHeader {
    background: linear-gradient(180deg, #051113 0%, var(--green) 100%);
    min-height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 120px 64px 80px;
    /* gap: 8px; */
}

.blogSubHead {
    color: var(--orange);
    font-size: 18px;
    font-family: 'inter';
}

.blogMainHead {
    color: white;
    font-size: 48px;
    font-weight: 700;
}

.blogHeaderBox {
    background-color: white;
    padding: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    max-width: 1200px;
    border-radius: 45px;
    margin: 40px auto 0;
}

.blogHeaderImg {
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogHeaderImg>img {
    border-radius: 20px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.blogHeaderContent {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    text-align: left;
    color: var(--black);
}

.blogHeaderLabel {
    background-color: var(--green);
    color: white;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 14px;
    align-self: flex-start;
    font-weight: 500;
}

.blogHeaderHead {
    /* color: black; */
    font-weight: 700;
    font-size: 28px;
}

.blogHeaderText {
    overflow-y: hidden;
    /* max-height: 260px; */
    /* min-height: 260px; */
}

.blogsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    justify-content: center;
    padding: 64px;
    max-width: 1200px;
    margin: 0 auto;
}

.blogBox {
    box-shadow: 0 17px 93px #2046920f;
    background-color: white;
    border-radius: 20px;
    padding: 14px 14px 30px;
    color: var(--black);
    text-align: left;
    font-size: 15px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    min-height: 696px;
}

.blogBox:hover {
    background-color: #062c33;
    color: white !important;
}

.blogBox:hover>.blogHead {
    color: white;
}

.blogImg {
    width: 100%;
    margin-bottom: 32px;
    height: 260px;
}

.blogImg>img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* object-position: top; */
}

.blogContent {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.blogHead {
    /* color: black; */
    font-weight: 700;
    font-size: 20px;
    overflow-y: hidden;
    max-height: 81px;
}

.blogText {
    overflow-y: hidden;
    max-height: 260px;
    min-height: 260px;
}


@media only screen and (max-width: 938px) {
    .blogsContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (orientation: portrait) {

    .blogHeader {
        min-height: unset;
        padding: 120px 24px 40px;
    }

    .blogSubHead {
        font-size: 14px;
    }

    .blogMainHead {
        font-size: 32px;
    }

    .blogHeaderBox {
        padding: 20px;
        padding-bottom: 32px;
        grid-template-columns: 1fr;
        margin: 20px auto 0;
        gap: 20px;
    }

    .blogHeaderImg {
        height: unset;
    }

    .blogHeaderContent {
        padding: 0 10px;
        font-size: 14px;
    }

    .blogHeaderLabel {
        font-size: 13px;
    }

    .blogHeaderHead {
        font-size: 18px;
    }

    .blogsContainer {
        grid-template-columns: 1fr;
        padding: 64px 24px;
    }

    .blogBox {
        padding: 14px 14px 30px;
        font-size: 14px;
        min-height: unset;
    }

    .blogImg {
        height: 180px;
    }

    .blogContent {
        padding: 0 12px;
    }

    .blogHead {
        max-height: 72px;
        font-size: 18px;
    }

    .blogText {
        max-height: 156px;
        min-height: unset;
    }
}