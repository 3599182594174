.CompanyPage{
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.companyDiv{
    padding: 64px;
    text-align: left;
    min-width: 70%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    /* font-size: 14px; */
}

.companyTopFlex{
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 32px;
}

.companyData{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.companyImgsDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 4px;
    width: max-content;
    height: max-content;
    background-color: #0000001b;
    cursor: pointer;
}

.companyImgsDiv>div{
    height: 130px;
    width: 130px;
    /* border: 1px solid #00000056; */
    background-color: white;
}

.companyImgsDiv>div>img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.company_name{
    font-weight: 700;
    color: var(--orange);
    font-size: 32px;
}

.company_facilities{
    /* font-size: 14px; */
    background-color: var(--grey);
    border-radius: 4px;
    padding: 2px 8px;
    align-self: flex-start;
    font-weight: 600;
    color: #00000099;
    margin: 24px 0 8px;
}

.company_PR{
    margin-bottom: 24px;
}

.company_desc{
    color: #00000099;
    max-width: 600px;
}
.company_date{
    color: #00000080;
}

.jobTitle{font-weight: 700;}

.getinTouchBtn{
    /* flex-grow: 1; */
    max-width: 140px;
    border-radius: 20px;
    padding: 6px 16px;
    border: none;
    background-color: var(--green);
    color: white;
    font-weight: 600;
}
.getinTouchBtn.two{
    max-width: 180px;
}

.GetinTouchDiv{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.GIT_input{
    border-radius: 8px;
    padding: 12px 16px;
    background-color: #6484961A;
    border: 0.25px solid #2a2f336e;
    font-size: 14px;
    color: #2A2F3399;
    max-width: 300px;
}

.imgCarousel{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #00000098;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
}

.fullImg{
    height: 400px;
    width: 400px;
    margin: 0 auto;
    padding: 32px 64px;
}
.fullImg>img{
    box-shadow: 0px 10px 20px 0px #00000034;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.swiper{
    display: flex !important;
}

.closeCarouselBtn{
    position: absolute;
    top: 10%;
    right: 10%;
    font-size: 32px;
    color: white;
    cursor: pointer;
}

@media only screen and (orientation: portrait){
    .companyDiv{
        padding: 64px 24px;
    }
    
    .companyTopFlex{
        display: grid;
        grid-template-columns: auto;
        gap: 32px;
    }

    .companyImgsDiv{
        margin: 0 auto;
    }
    
    .companyImgsDiv>div{
        height: 100px;
        width: 100px;
    }
    
    .company_name{
        font-size: 24px;
    }
    
    .fullImg{
        height: 250px;
        width: 250px;
        padding: 32px 32px;
    }
}