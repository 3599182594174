.OneStopSoln{
    margin: 0 64px;
    padding: 32px 72px;
    border-radius: 20px;
    display: grid;
    gap: 72px;
    grid-template-columns: 40% 50%;
    /* justify-content: center; */
    align-items: center;
    border: 1px solid var(--black);
    /* background-image: linear-gradient(180deg, #332F2A 0%, #221505 100%); */
    background-image: linear-gradient(180deg, #051113 0%, var(--green) 100%);
    transition: margin 300ms ease-in-out;
}

.OneStopSoln:hover{
    margin: 0 60px;
}

.OSS_content{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    color: white;
    text-align: left;
    gap: 24px;
    max-width: 100%;
}

.OSS_content.USP{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    gap: 20px;
    /* max-width: 80%; */
    background-color: #FFFFFF33;
    background-image: linear-gradient(180deg, #051113 0%, var(--green) 100%);
    border-radius: 20px;
    padding: 32px 48px;
    min-height: 324px;
}

/* .OSS_content_Head{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    text-align: right;
} */

.OSStext{
    /* max-width: 40%; */
    text-align: left;
    /* padding: 8px 0; */
}

.OSS_head{
    font-size: 32px;
    font-weight: 700;
}

.OSS_img{
    width: 100%;
}

.OSS_btn{
    background-color: transparent;
    border: 1px solid var(--orange);
    font-size: 20px;
    font-weight: 700;
    color: var(--orange);
}

.OSS_btn:hover{
    padding: 10px 18px;
    transition: padding 150ms ease-in-out;
}