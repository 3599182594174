.Footer{
    background: linear-gradient(180deg, #051113 0%, var(--green) 100%);
    padding: 72px 64px;
    color: white;
    min-height: 300px;
    font-size: 14px;
}

.footerBold{
    font-weight: 600; 
    font-size: 16px;
}


.footerTerms{
    padding: 32px 0 0;
    font-size: 12px;
    display: flex;
    justify-content: center;
    gap: 56px;
    border: none;
    border-top: 0.5px solid #FFFFFF66;
}

.footerContent{
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    min-height: 290px;
    column-gap: 80px;
}

.footerHeading{
    min-height: 80px;
    text-align: left;
    display: flex;
    align-items: center;
}

.footerLogo{
    align-self: flex-start;
    height: 40px;
}
 
.footerAddress{
    text-align: left;
}

.footerAddress>div{
    margin-bottom: 16px;
}

.footerList{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 16px;
    justify-items: start;
    /* align-items: end; */
}

.footerSocialMedia{
    display: flex;
    gap: 8px;
    align-items: center;
}

.footerOrange{
    color: var(--orange);
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-start;
    text-align: left;
    cursor: pointer;
}

.footerCopyright{
    font-size: 12px;
    margin-top: 32px;
}

@media only screen and (orientation: portrait){
    .Footer{
        padding: 72px 32px;
    }
    .footerContent{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 24px;
    }

    .footerList{
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;
    }

    .footerOrange{
        margin-bottom: 32px;
    }
}