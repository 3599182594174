.App {
  text-align: center;
  color: var(--black);
  letter-spacing: default;
}

a{
  text-decoration: none;
  color: inherit;
}

button{
  padding: 8px 16px;
  border-radius: 24px;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}

.subHead{
  font-size: 24px;
  font-weight: 700;
}

.subHead.search{
  text-align: left;
  margin-bottom: 8px;
}

.text{
  font-family: 'inter';
  font-weight: 400;
}

.no-scroll {
  overflow:unset;
  /* width: 100vw; */
  /* height: 100%; */
}

@media only screen and (orientation: portrait){
  .text{
    font-size: 14px;
  }
  .subHead{
    font-size: 18px;
  }
}