.Dashboard {
    padding: 64px;
    display: flex;
    min-height: calc(100vh - 179px);
    gap: 56px;
}

.mainDash {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 56px;
    flex-grow: 1;
}

.dash_DataDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    max-width: 764px;
}

.dash_Head {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dash_BoxFlex {
    display: flex;
    gap: 20px;
}

.dash_Box {
    border-radius: 20px;
    /* border: 1px solid #4A4239; */
    max-width: 300px;
    min-width: 180px;
    padding: 20px;
    background-color: white;

    border: 1px solid transparent;

    background: linear-gradient(to right, white, white), linear-gradient(142.65deg, #FFFFFF -10.51%, rgba(227, 143, 38, 0.4) 110.46%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    box-shadow: 0px 5px 10px 0px #0000001A;
}

.dash_boxNum {
    font-size: 32px;
    color: var(--orange);
    font-weight: 700;
}

.dash_boxText {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
}


.dash_ServicesDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
}

.dash_servicesHead {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.dash_servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 14px;
}

.dash_service {
    padding: 20px;
    text-align: left;
    border-radius: 20px;
    border: 1px solid #332F2A;
    background-color: white;

    border: 1px solid transparent;

    background: linear-gradient(to right, white, white), linear-gradient(142.65deg, #FFFFFF -10.51%, rgba(227, 143, 38, 0.4) 110.46%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    box-shadow: 0px 3px 8px 0px #0000001A;
}

.service_name {
    font-weight: 700;
    color: var(--orange);
}

.addNewService {
    align-self: baseline;
}

.addNewService>button {
    max-width: 392px;
    border-radius: 20px;
    padding: 8px 16px;
    border: none;
    background-color: var(--green);
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.dashModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000070;
    z-index: 6;
}

.modalBox {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 32px;
    gap: 16px;
    box-shadow: 0px 10px 20px 0px #0000001A;
    position: relative;
    border: 1px solid var(--orange);
    overflow-y: scroll;
    max-height: 550px;
}

.modalBox::-webkit-scrollbar {
    display: none;
}

.submitNewServices {
    border: none;
    color: white;
    background-color: var(--green);
}

.closeDashModal {
    position: absolute;
    top: 16px;
    right: 32px;
    cursor: pointer;
}

.profileSettings {
    cursor: pointer;
}

.dashSideBarContainer {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
    position: sticky;
    top: 0;
    bottom: 0;
}

.dashSideBar {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
    border-radius: 20px;
    border: 1px solid #332f2a25;
    background-color: #00000010;
    color: var(--black);
    box-shadow: 0px 10px 20px 0px #ffffff1a;
}

.sidebarTitle {
    font-weight: 600;
    color: var(--orange);
}

.editInfoInput,
.PRInfoInput {
    max-width: 500px;
    min-width: 400px;
    border-radius: 20px;
    padding: 8px 16px;
    border: 1px solid #0000002d;
    color: var(--black);
    margin: 4px 0;
}

.editInfoInput.inputfile {
    max-width: 400px;
    min-width: 300px;
}

.PRInfoInput {
    max-width: 100%;
    min-width: 150px;
}

.PRInputDiv {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    gap: 30px;
}

.modalBox.modal3 {
    font-size: 14px;
    text-align: left;
    padding-top: 0;
}

.editInfoHeader {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 32px 0 16px;
    z-index: 5;
}

.closeEditInfoModal {
    position: absolute;
    right: 0;
    top: 32px;
    cursor: pointer;
}


.validerrordash {
    font-size: 11px;
    margin: 0;
    color: #d22323;
    position: absolute;
    bottom: -10px;
    right: 10px;
    font-weight: 400;
}

.errordash {
    font-size: 11px;
    margin: 0;
    color: #d22323;
    position: absolute;
    bottom: 20px;
    left: 32px;
    font-weight: 400;
}

.editIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
}

.userImgsDiv {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.userImgEdit {
    display: flex;
    gap: 16px;
    align-items: center;
    /* justify-content: ; */
}

.userImgEdit>img {
    height: 70px;
    width: 70px;
    object-fit: cover;
    object-position: center;
}

.ManageAcctBtn {
    border: 1px solid var(--green);
    display: flex;
    background-color: white;
    align-items: center;
    gap: 8px;
    justify-content: center;
}

.dashManageAccount {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
    border-radius: 20px;
    border: 1px solid #332f2a25;
    background-color: #00000010;
    color: var(--black);
    box-shadow: 0px 10px 20px 0px #ffffff1a;
}

.showEditPhoneBtn {
    background-color: var(--green);
    color: white;
}

.editPhoneDiv {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
}

.submitPhoneBtn {
    margin-top: 16px;
    background-color: white;
    border: 1px solid var(--green);
    color: var(--green);
    max-width: 160px;
}

.editPhoneInput {
    border-radius: 20px;
    padding: 8px 16px;
    border: 1px solid #0000002d;
    color: var(--black);
}

.muidropdown_Mobile{
    display: none;
}

.muidropdown{
    display: block;
}

@media only screen and (orientation: portrait) {
    .Dashboard {
        padding: 24px;
        flex-direction: column;
    }

    .dash_Head {
        font-size: 18px;
    }

    .dash_Box {
        max-width: 100%;
        min-width: unset;
        padding: 20px;
    }

    .dash_boxNum {
        font-size: 24px;
    }

    .dash_service {
        font-size: 12px;
    }

    .dashSideBarContainer {
        width: 100%;
        position: static;
    }

    .editInfoInput,
    .PRInfoInput {
        max-width: 500px;
        min-width: 200px;
    }

    .editInfoInput.inputfile {
        max-width: 400px;
        min-width: 200px;
    }

    .PRInfoInput {
        max-width: 100%;
        min-width: 100px;
    }

    .PRInputDiv {
        flex-direction: column;
        justify-content: flex-start;
        gap: 4px;
    }

    .muidropdown_Mobile{
        display: block;
    }
    
    .muidropdown{
        display: none;
    }

}