.HomeNav{
    padding: 8px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    gap: 32px;
    font-weight: 400;
    color: var(--black);
    position: fixed;
    top: 16px;
    left: 48px;
    /* right: 48px; */
    /* width: calc(100% - 80px - 48px); */
    max-width: 800px;
    background-color: white;
    z-index: 9;
    box-shadow: 0px 2px 8px 0px #00000011;
    border-radius: 50px;
    border: 1px solid #ffaa0052;
}

.navLogoDiv{
    display: flex;
    align-items: center;
}

.navLogo{
    height: 28px;
    /* margin-top: 4px; */
}

.navItems{
    display: flex;
    flex-grow: 1;
    gap: 24px;
    justify-content: space-evenly;
}
.navItems>div{cursor: pointer;}

.navBtns{
    display: flex;
    gap: 16px;
}

.navLoginBtn{
    border: 1px solid var(--black);
    background-color: white;
    min-height: 42px;
}

.navSignupBtn{
    border: 1px solid var(--green);
    background-color: var(--green);
    color: white;
    display: flex;
    align-items: center;
    gap: 4px;
    min-height: 42px;
    width: 110px;
    transition: width 200ms ease-in-out;
}

.navSignupBtn.navbtnwidth{
    width: 140px;
}

.navSignupBtn>.css-i4bv87-MuiSvgIcon-root{
    height: 0.8em !important;
}

.navItemBold{
    font-weight: 700;
}

.hamburgerIcon{
    display: none;
    cursor: pointer;
}

.mobileNavContainer{
    position: fixed;
    background-color: #00000065;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: flex-end;
    height: 100vh;
    height: 100dvh;
    height: 100lvh;
}

.mobilenavItems{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    font-size: 16px;
}
.mobilenavItems>div{cursor: pointer;}

.mobilenavBtns{
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-bottom: 48px;
}

.mobileNav{
    max-width: 300px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
    background-color: white;
    padding: 24px;
}

.closeMobileNavBtn{
    font-size: 18px;
    align-self: flex-end;
    cursor: pointer;
}

@media only screen and (max-width: 1100px){
    .HomeNav{
        max-width: 100%;
        right: 48px;
    }
}

@media only screen and (orientation: portrait){
    .HomeNav{
        padding: 16px 24px;
        left: 24px;
        right: 24px;
        max-width: 100%;
    }

    .navItems{
        display: none;
    }
    .navBtns{
        display: none;
    }

    .hamburgerIcon{
        display: block;
    }

    .mobileNavContainer{
        display: flex;
    }
}