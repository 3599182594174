.USPs{
    margin: 72px 64px;
    /* padding: 40px 100px; */
    border-radius: 20px;
    display: flex;
    /* flex-direction: column; */
    gap: 32px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--black); */
    /* background-image: linear-gradient(180deg, #051113 0%, var(--green) 100%); */
    /* background-color: var(--green); */
    color: white;
    /* background-image: linear-gradient(180deg, #332F2A 0%, #221505 100%); */
}

.USP_head{
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 16px;
}

.USP_text{
    font-size: 14px;
}

.USP_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    gap: 16px;
    max-width: 1000px;
}

.USP_box{
    padding: 16px 32px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    font-family: 'inter';
    /* border: 2px solid transparent; */
    border: 1px solid var(--green);
    border-radius: 20px;
    text-align: left;
    /* background: linear-gradient(to right, white, white), linear-gradient(91.78deg, rgba(255, 255, 255, 0.4) 0%, #272727 100%); */
    
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    /* background-color: #FFFFFF33; */
    transition: padding 200ms ease-in-out;
    color: var(--green);
    width: 200px;
    height: 50px;
}

.USP_box:hover{
    padding: 20px 32px;
}

.USP_foot{
    padding: 16px 0;
    background-color: #FFFFFF33;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;
}

.USP_footContent{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}


@media only screen and (orientation: portrait){
    .USPs{
        flex-direction: column;
        margin: 72px 24px;
    }

    .USP_container{
        gap: 8px;
    }
    
    .USP_box{
        padding: 16px 24px;
        /* width: calc(50% - 10px - 48px); */
        height: 30px;
        font-size: 12px;
        display: grid;
        grid-template-columns: 40px auto;
        justify-content: start;
    }
    .USP_box>img{
        width: 30px;
    }
    
    .USP_box:hover{
        padding: 20px 24px;
    }
}