  .Line{
    width: 100%;
    overflow: hidden;
    height: 8px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .Line_line{
    background-color: rgba(0, 0, 0, 0.13);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.5px;
    bottom: 2.5px;
  }

  .Line_circle{
    height: 100%;
    width: 8px;
    background-color: var(--green);
    border-radius: 20px;
    animation: linearAnimation 4s infinite;
  }


  @keyframes linearAnimation {
    0% {transform: translateX(-0px);}
    100% {transform: translateX(400px);}
  }