.Benefits{
    padding: 0 64px;
    margin: 100px auto;
}

.stepsContainer{

}

.stepsContainer>img{
    object-fit: cover;
    width: 75%;
}


@media only screen and (orientation: portrait){
    .Benefits{
        padding: 0 24px;
    }

    .stepsContainer>img{
        width: 100%;
    }
}

/* .benefitsHead{
    font-size: 20px;
    margin-bottom: 32px;
    font-weight: 700;
}

.stepsImg{
    object-fit: fill;
    width: 70%;
}

.benefitsContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.benefitCard{
    padding: 14px;
    max-width: 100px;
    min-height: 148px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    background-color: white;

    border: 1px solid transparent;
    border-radius: 20px;

    background: linear-gradient(to right, white, white), linear-gradient(142.65deg, #FFFFFF -10.51%, rgba(227, 143, 38, 0.4) 110.46%); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    box-shadow: 0px 10px 20px 0px #0000001A;
}

.BC_num{
    font-size: 40px;
    font-weight: 700;
    color: var(--orange);
}

.BC_text_bold{
    font-size: 14px;
    font-weight: 800;
    color: black;
}

.BC_text{
    font-size: 14px;
    font-weight: 700;
    color: black;
}

.benefitsText{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
}

.benefitsText > .text{
    max-width: 780px;
} */
