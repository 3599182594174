.SearchPage {
    padding: 32px 64px 64px;
    min-height: calc(100vh - 79px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
}

.SearchPage_Header{
    display: flex;
    flex-direction: column;
}

.SearchPage_subHeader{
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-end;
}

.lookingFlexiBtn{
    background-color: #C775FE;
    padding: 4px 12px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}
.lookingFlexiBtn:hover{
    background-color: #b23fff;
}

.showMainSearchBtn{
    background-color: #fa9066;
    padding: 4px 12px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}
.showMainSearchBtn:hover{
    background-color: #ff804e;
}

.searchPageSearch{
    display: flex;
    /* grid-template-columns: auto auto; */
    flex-wrap: wrap;
    /* justify-items: center; */
    /* justify-content: start; */
    gap: 16px;
    font-size: 16px;
    align-items: center;
    position: relative;
    color: white !important;
}

.searchPageSearchMobile{
    display: none;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 12px;
    align-items: center;
    position: relative;
    color: white !important;
}


.FlexiPage_Banner {
    border-radius: 45px;
    margin: 0px 0 32px;
    background-image: url(../../assets/flexibg.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 360px;
}

.FlexiPage_Box {
    /* padding: 40px 48px; */
    border-radius: 45px;
    border: 1px solid #FFF;
    /* background: linear-gradient(121deg, rgba(255, 255, 255, 0.50) 31.41%, rgba(255, 255, 255, 0.00) 116.44%); */
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.12);
    min-height: 360px;
    /* backdrop-filter: blur(7px); */
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
}

.FlexiPage_ImgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0;
    /* backdrop-filter: blur(7.5px); */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* z-index: -1; */
}

.FlexiPage_ImgDiv>img {
    max-width: 100%;
    /* max-height: 100%; */
    max-height: 360px;
}

.FlexiPage_Content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: center;
    align-items: center;
    border: 1px solid #FFF;
    background: linear-gradient(121deg, rgba(255, 255, 255, 0.671) 31.41%, rgba(255, 255, 255, 0.00) 116.44%);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(7.5px);
    padding: 24px 32px;
    border-radius: 45px;
}

.flexiPage_Heading {
    color: var(--green);
    font-size: 40px;
    font-weight: 700;
}

.flexiSearchContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    margin-top: 16px;
    /* border: 1px solid #FFF;
    background: linear-gradient(121deg, rgba(255, 255, 255, 0.50) 31.41%, rgba(255, 255, 255, 0.00) 116.44%);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(7.5px);
    padding: 24px 32px;
    border-radius: 45px; */
}

.flexiHeadText{
    color: var(--green);
    font-weight: 700;
    font-size: 14px;
}

.FlexiPointersBanner{
    padding: 40px 48px;
    border-radius: 45px;
    border: 1px solid #FFF;
    background: linear-gradient(121deg, rgba(255, 255, 255, 0.50) 31.41%, rgba(255, 255, 255, 0.00) 116.44%);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(7.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.flexiPointersContainer{
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    /* display: flex; */
    /* flex-wrap: wrap; */
    justify-content: center;
    margin: 56px 0 48px;
    gap: 32px;
}

.bannerPointer{
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.pointerNum{
    padding: 8px 12px;
    border-radius: 12px;
    background-color: #ff804e;
    color: white;
    font-weight: 700;
    font-size: 24px;
}

.pointerText{
    color: var(--green);
    font-weight: 700;
    font-size: 14px;
}

.arrow{
    position: absolute;
    top: 16px;
    left: 80%;
    right: -48%;
    overflow: visible;
}
.arrow>img{
    object-fit: contain;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 928px){
    .flexiPointersContainer{
        /* gap: 16px; */
        grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
    .arrow.mobile{
        display: none;
    }
}

@media only screen and (orientation: portrait) {
    .SearchPage {
        padding: 24px;
    }

    .searchPageSearch{
        display: none;
    }
    
    .searchPageSearchMobile{
        display: flex;
    }

    .flexiPage_Heading{
        font-size: 32px;
    }

    .flexiSearchContainer {
        max-width: 260px;
    }
    .FlexiPage_Content{
        padding: 20px 20px;
        border-radius: 30px;
    }

    .FlexiPointersBanner{
        padding: 24px 24px;
    }

    .pointerNum{
        font-size: 18px;
    }
    
    .pointerText{
        font-size: 11px;
    }


}

@media only screen and (max-width: 440px){
    .flexiPointersContainer{
        /* gap: 16px; */
        grid-template-columns: repeat(2, 1fr);
    }
    .arrow.mobile{
        display: block;
    }
    .arrow.mobilesmall{
        display: none;
    }
}