.FAQ_content{
    /* min-height: 500px; */
}


.FAQs{
    margin: 120px auto 80px;
    padding: 32px 64px;
    display: grid;
    grid-template-columns: 4fr 6fr;
}

.FAQ_head{
    justify-self: start;
    font-size: 88px;
    font-weight: 700;
    color: var(--orange);
}

.FAQ_content{
    justify-self: stretch;
    text-align: left;
}

.FAQ_box{
    margin-top: 24px;
    padding: 18px;
    border: 1px solid var(--green);
    font-family: 'inter';
    border-radius: 20px;
    font-weight: 600;
    color: black;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    cursor: pointer;
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.content{
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    font-weight: 400;
    font-size: 14px;
}

.content.show{
    height: auto;
    max-height: 999px;
    /* max-height: 0px; */
    overflow: hidden;
    margin-top: 24px;
}

.faqArrows{
    border-radius: 20px;
    background-color: var(--grey);
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.faqArrowsOrange{
    border-radius: 20px;
    background-color: #E38F2633;
    color: var(--orange);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (orientation: portrait){
    .FAQs{
        margin: 80px auto;
        padding: 24px 24px;
        grid-template-columns: 1fr;
    }
    .FAQ_head{
        font-size: 64px;
    }
}