.FC_box{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    padding: 16px;
    min-width: 360px;
    max-width: 360px;
    min-height: 206px;
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to right, white, white), linear-gradient(180deg, #332F2A 0%, #221505 100%);; 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    cursor: pointer;
}

.FC_TopFlex{
    display: grid;
    grid-template-columns: 1fr 120px;
    gap: 32px;
}

.FC_bottomFlex{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.FC_PR{
    color: #00000099;
}
.FC_flexiPR{
    color: #C775FE;
    font-weight: 400;
}

.FC_dataDiv{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.FC_ImgsDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 4px;
    width: max-content;
    height: max-content;
    background-color: #0000001b;
}

.FC_ImgsDiv>div{
    height: 50px;
    width: 50px;
    /* border: 1px solid #00000056; */
    background-color: white;
}

.FC_ImgsDiv>div>img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.newDiv{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

.new{
    background-color: var(--green);
    padding: 4px 8px;
    color: white;
    font-weight: 800;
    border-radius: 4px;
    align-self: flex-start;
}

.flexi{
    background-color: #C775FE;
    padding: 4px 8px;
    color: white;
    font-weight: 800;
    border-radius: 4px;
    align-self: flex-start;
}

.FC_name{
    font-weight: 700;
    color: var(--orange);
    font-size: 16px;
}

.FC_facilities{
    font-size: 14px;
    background-color: var(--grey);
    border-radius: 4px;
    padding: 2px 8px;
    align-self: flex-start;
    font-weight: 600;
    color: #00000099;
    height: 19px;
    overflow-y: hidden;
}

.FC_desc{
    color: #00000099;
}
.FC_date{
    color: #00000080;
}

.FC_cityDiv{
    height: 16px;
    overflow-y: hidden;
}


@media only screen and (orientation: portrait){
    .FC_box{
        font-size: 11px;
    }
    .FC_container .FC_box{
        min-width: 250px;
        max-width: 250px;
        min-height: 222px;
    }
    .Companies_container .FC_box{
        min-width: calc(100% - 32px);
        max-width: calc(100% - 32px);
    }
    
    .FC_TopFlex{
        display: grid;
        grid-template-columns: 1fr 80px;
        gap: 8px;
    }
    
    
    .FC_ImgsDiv>div{
        height: 30px;
        width: 30px;
    }
    
    .FC_facilities{
        font-size: 12px;
    }
}