.AddCompany {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: calc(100vh - 79px);
    padding: 64px;
}

.recruiting_input_box {
    /* min-height: 400px; */
    /* max-width: 400px; */
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
    border: 1px solid #00000033;
    box-shadow: 0px 20px 154.9px 0px #00000040;
    padding: 32px 32px 40px;
    border-radius: 20px;
    text-align: left;
    font-size: 14px;
}


.recruiting_input_heading {
    color: black;
    font-size: 32px;
    font-weight: 700;

}

.recruiting_input_Subheading {
    color: #000000BF;
    font-size: 14px;
    font-weight: 700;
    justify-self: start;
    margin-bottom: 8px;
}

.recr_TabsDiv {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2px;
}

.recr_Tab {
    background-color: var(--grey);
    height: 3px;
    border-radius: 4px;
}

.orangeTab {
    background-color: var(--orange);
    height: 3px;
    border-radius: 4px;
}

.recr_input {
    border-radius: 16px;
    padding: 8px 16px;
    border: 1px solid #00000066;
    width: 360px;
    margin-bottom: 16px;
    margin-top: 12px;
}

.recr_phone_input_div {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
}

.recr_phone_select {
    width: 100%;
    border-radius: 16px;
    padding: 7px 16px;
    margin-top: 12px;
    margin-bottom: 16px;
    border: 1px solid #00000066;
}

.recr_select {
    width: 100%;
    border-radius: 16px;
    padding: 7px 16px;
    margin-top: 12px;
    margin-bottom: 16px;
    border: 1px solid #00000066;
}

.recr_range_input_div {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    /* justify-content: space-between; */
}

.recr_range_input {
    border-radius: 16px;
    padding: 8px 16px;
    border: 1px solid #00000066;
    margin-top: 12px;
    margin-bottom: 16px;
    flex-grow: 1;
    max-width: 150px;
}

.addImgBtn {
    margin: 16px 0 32px;
    border-radius: 20px;
    padding: 4px 12px;
    border: 1px solid var(--orange);
    background-color: white;
    color: var(--orange);
    font-weight: 600;
    font-size: 13px;
}

.recr_phone_input {
    border-radius: 16px;
    padding: 8px 16px;
    border: 1px solid #00000066;
    margin-top: 12px;
    margin-bottom: 16px;
    width: 250px;
}


.recr_btns_div {
    display: flex;
    gap: 16px;
}

.recruiting_next_btn {
    flex-grow: 1;
    max-width: 392px;
    border-radius: 20px;
    padding: 6px 16px;
    border: none;
    background-color: var(--green);
    color: white;
    font-weight: 600;
}

.recruiting_back_btn {
    flex-grow: 1;
    max-width: 392px;
    border-radius: 20px;
    padding: 6px 16px;
    border: 1px solid var(--green);
    background-color: white;
    color: var(--green);
    font-weight: 600;
}

.css-1tw9w9k-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 9px 39px 9px 9px !important;
    border-radius: 20px !important;
    margin-top: 12px !important;
    margin-bottom: 16px !important;
}

.css-1tw9w9k-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 0 !important;
}



.recruiting_input_box>div>div,
.recr_phone_input_div>div,
.PasswordDiv {
    position: relative
}

.error,
.validerror {
    font-size: 11px;
    margin: 0;
    color: #d22323;
    position: absolute;
    bottom: 0;
    right: 10px;
    font-weight: 400;
}

.error {
    left: 10px;
    bottom: -10px;
    font-weight: 600;
    font-size: 12px;
}

.recruiting_input_box>div {
    position: relative;
}


.invalid,
.invalid:focus {
    outline: none;
    /* border-color: #9b2b2b; */
    border-color: #d22323;
}

.valid,
.valid:focus {
    outline: none;
    border-color: #299158;
    /* border: 1px solid #299158; */
}

.fileInputDiv {
    position: relative;
}

.inputFile {
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 0px;
}

.inputFileLabel,
.inputFileLabel1,
.inputFileLabel2,
.inputFileLabel3 {
    background-color: var(--green);
    position: absolute;
    left: 13px;
    top: 39px;
    color: white;
    width: 92px;
    height: 22px;
    pointer-events: none;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.inputFileLabel1 {
    top: 90px
}

.inputFileLabel2 {
    top: 142px
}

.inputFileLabel3 {
    top: 192px
}

.selectservicetext {
    margin-bottom: 12px;
}

.city_input_div {
    margin-bottom: 16px;
    display: block;
}

.city_input_div_Mobile{
    margin-bottom: 16px;
    display: none;
}

.city_input_text {
    margin-bottom: 12px;
}

.recr_flexi_input {
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 16px;
}

.recr_flexi_radio {
    display: flex;
    align-items: center;
    gap: 8px;
}

.servicesdropdown_Mobile {
    display: none;
}

.servicesdropdown {
    display: block;
}

@media only screen and (orientation: portrait) {
    .AddCompany {
        padding: 24px;
        font-size: 14px;
    }

    .recruiting_input_box {
        padding: 24px 24px 32px;
        margin-top: 32px;
        font-size: 14px;
    }


    .recruiting_input_heading {
        font-size: 24px;

    }

    .recr_input {
        width: 200px;
    }

    .recr_phone_input_div {
        grid-template-columns: auto;
        gap: 8px;

    }

    .recr_phone_select {
        width: 90px;
    }


    .recr_range_input_div {
        flex-direction: column;
    }

    .recr_phone_input {
        width: 200px;
    }

    .recr_btns_div {
        gap: 8px;
    }

    .recr_flexi_radio {
        gap: 2px;
    }

    .servicesdropdown_Mobile {
        display: block;
    }

    .servicesdropdown {
        display: none;
    }

    .city_input_div {
        display: none;
    }
    
    .city_input_div_Mobile{
        display: block;
    }
}