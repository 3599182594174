.Companies{
    padding: 64px;
    min-height: calc(100vh - 79px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
}

.Companies_container{
    display: flex;
    gap: 32px;
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;
}

@media only screen and (orientation: portrait){
    .Companies{
        padding: 32px 24px;
    }
    .Companies_container{
        flex-direction: column;
    }
}