@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: 'open sans'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  /* --black : #332F2A; */
  --black : #051113;
  /* --orange : #E38E27; */
  /* --orange : #FF0000; */
  --orange : #FFAA00;
  --grey : #0000001A;
  --green: #164F55;
}