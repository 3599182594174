.ComparisonPage{
    display: flex;
    padding: 64px;
    gap: 64px;
    text-align: left;
    font-size: 14px;
    min-height: 100vh;
    align-items: flex-start;
}

.CompanyCompareTable{
    display: grid;
    grid-template-columns: auto auto auto;
    border: 1px solid var(--green);
    border-radius: 25px;
    width: 50%;
    position: sticky;
    top: 144px;
}

.CompanyCompareTable>div{
    padding: 8px 16px;
    color: rgba(0, 0, 0, 0.651);
    font-weight: 700;
    display: flex;
    align-items: flex-start;
    border: 1px solid rgba(0, 0, 0, 0.082);
}

.CompanyCompareTable>.criteria{
    font-weight: 700;
    color: var(--green);
    font-size: 16px;
}

.compare_cities{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}

.compareCriteriaHead{
    font-size: 24px;
    font-weight: 700;
    color: var(--orange);
}

.compareCriteriaDiv{
    display: flex;
    gap: 16px;
}

.compareCriteriaBtn{
    background-color: #C775FE;
    padding: 4px 8px;
    color: white;
    font-weight: 800;
    border-radius: 4px;
    cursor: pointer;
}

.compareCriteriaBtn:hover{
    background-color: #ae3ff8;
}

.compareWith_Container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media only screen and (max-width: 1024px){
    .ComparisonPage{
        align-items: flex-start;
        flex-direction: column;
    }
    
    .CompanyCompareTable{
        width: 100%;
        position: static;
        top: unset;
    }
}

@media only screen and (orientation: portrait){
    .ComparisonPage{
        padding: 64px 24px;
        font-size: 12px;
    }
    
    .CompanyCompareTable{
        grid-template-columns: repeat(3, 1fr);
        overflow-x: scroll;
    }
    .CompanyCompareTable::-webkit-scrollbar {
        display: none;
    }
    
    .CompanyCompareTable>.criteria{
        /* position: sticky;
        left: 0; */
    }

    .compareWith_Container .FC_box{
        min-width: calc(100% - 32px);
        max-width: calc(100% - 32px);
    }

    .CompanyCompareTable>.criteria{
        font-size: 12px;
    }
}
