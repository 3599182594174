.HomeMain{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 64px;
    margin: 0 auto;
    background-image: url(../../../assets/Header\ 77.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 0px);
    color: var(--black);
}

.landingContent{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    gap: 32px;
    /* flex-grow: 1; */
    width: 60%;
    text-align: left;
    padding-left: 48px;
    margin-top: 64px;
    margin-bottom: 16px;
}

.landingHeadingDiv{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.mainHead{
    font-size: 32px;
    font-weight: 700;
    color: var(--orange);
    position: relative;
    /* width: 500px; */
    max-width: 100%;
    height: 100px;
    overflow: hidden;
}

.animText {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    animation: textAnimation 15s infinite;
}

@keyframes textAnimation {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    1% {
        opacity: 1;
        transform: translateX(0px);
    }
    32% {
        opacity: 1;
        transform: translateX(0px);
    }
    33% {
        opacity: 0;
        transform: translateX(-50px);
    }
    33.33% {
        opacity: 0;
        transform: translateX(50px);
    }
    33.66% {
        opacity: 1;
        transform: translateX(0);
    }
    65% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 0;
        transform: translateX(-50px);
    }
    66.66% {
        opacity: 0;
        transform: translateX(50px);
    }
    66.99% {
        opacity: 1;
        transform: translateX(0);
    }
    99% {
        opacity: 1;
        transform: translateX(0px);
    }
    99.66% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 0;
        transform: translateX(50px);
    }
}

.landingsubHead{
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.landingSearchContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
    border: 1px solid #FFF;
    background: linear-gradient(121deg, rgba(255, 255, 255, 0.50) 31.41%, rgba(255, 255, 255, 0.00) 116.44%);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(7.5px);
    padding: 24px 32px;
    border-radius: 45px;
    /* width: max-content; */
}

.landingSearch{
    display: grid;
    grid-template-columns: auto auto;
    /* justify-items: center; */
    justify-content: start;
    gap: 16px;
    font-size: 16px;
    align-items: center;
    position: relative;
    color: white !important;
}

.landingSearchMobile{
    display: none;
    grid-template-columns: auto auto;
    /* justify-items: center; */
    justify-content: start;
    gap: 16px;
    font-size: 16px;
    align-items: center;
    position: relative;
    color: white !important;
}

.searcherror{
    font-size: 16px;
    margin: 0;
    color: #d22323;
    /* position: absolute;
    bottom: 40px;
    right: 120px; */
    font-weight: 600;
}

.searchInput{
    padding: 10px 16px;
    border-radius: 25px;
    width: 350px;
    border: 1px solid var(--black);
    font-weight: 400;
    font-size: inherit;
    position: relative;
}


.searchIcon{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.searchBtn{
    display: block;
    padding: 6px 16px;
    /* background-color: var(--black); */
    background-color: var(--green);
    border: 1px solid white;
    color: white;
}
.searchBtn:hover{
    background-color: white;
    border: 1px solid var(--orange);
    color: var(--orange);
}

.searchBtnMobile{
    display: none;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: var(--black);
    border: 1px solid white;
    color: white;
}

.searchBtnMobile:hover{
    background-color: white;
    border: 1px solid var(--orange);
    color: var(--orange);
}

.searchdropdown{
    justify-self: start;
    padding: 7px 10px;
    border: 1px solid #0000003c;
    border-radius: 25px;
    font-size: 12px;
    cursor: pointer;
    color: #000000c3;
}

.freqSearchText{
    text-align: left;
    /* padding-left: 16px; */
}

.freqSearchBtns{
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 8px;
    max-width: 440px;
}

.freqSearchBtns>button{
    /* border: 1px solid var(--black); */
    border: 1px solid #00000054;
    background-color: transparent;
    padding: 6px 10px;
}

.freqSearchBtns>button:hover{
    /* border: 1px solid var(--black); */
    border: 1px solid #00000054;
    background-color: var(--green);
    color: white;
    padding: 6px 10px;
}

.viewAllBtn{
    background-color: var(--black) !important;
    color: white;
}


.landingImgDiv{
    width: 40%;
    height: 100vh;
}

.landingImg{
    max-width: 100%;
    max-height: 120%;
    object-fit: cover;
    object-position: center;
    margin-top: -40px;
}


@media only screen and (max-width: 1100px){
    .landingContent{
        padding-left: 0;
    }
    /* .landingImg{
        margin-top: 70px;
    } */
}

@media only screen and (max-width: 845px){
    .mainHead{
        /* font-size: 24px; */
        font-weight: 700;
        color: var(--orange);
        width: 100% !important;
        height: 130px !important;
    }
}

@media only screen and (orientation: portrait){
    .HomeMain{
        padding: 0px 24px;
        flex-direction: column;
    }

    .landingContent{
        width: 100%;
        margin-top: 130px;
        flex-grow: 1;
        gap: 160px;
        z-index: 1;
    }

    .landingHeadingDiv{
        width: 100%;
    }
    
    .landingsubHead{
        font-size: 16px;
        max-width: 260px;
    }

    .landingSearchContainer{
        padding: 20px 20px;
        border-radius: 30px;
        max-width: 260px;
        font-size: 12px;
    }

    .landingSearch{
        display: none;
    }
    
    .landingSearchMobile{
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
        font-size: 12px;
    }

    .searchBtn{display: none;}

    .searchBtnMobile{display: flex;}
    
    .searchdropdown{
        font-size: 12px;
    }
    
    .freqSearchText{
        /* text-align: center; */
        padding: 0;
        font-size: 12px;
    }
    
    .freqSearchBtns>button{
        font-size: 12px;
    }

    .landingImgDiv{
        width: max-content;
        min-height: 300px;
        position: absolute;
        z-index: 0;
        top: 300px;
        right: 24px;
        display: none;
    }
    /* .landingImgDiv>svg{
        height: 300px;
    } */
    .landingImg{
        margin-top: 16px;
    }
}

@media only screen and (max-width: 360px){
    .mainHead{
        font-size: 28px;
    }
}
