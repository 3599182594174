.LoginPage {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: calc(100vh - 79px);
    padding: 64px;
    background-image: url(../../assets/OBJECTS.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.LoginBox {
    padding: 32px;
    box-shadow: 0px 10px 53.8px 20px #00000040;
    text-align: left;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 14px;
    position: relative;
}

.loginHead {
    font-size: 32px;
    margin-bottom: 8px;
    font-weight: 700;
}


.login_input {
    border-radius: 20px;
    padding: 10px 16px;
    border: 1px solid #00000066;
    width: 280px;
    margin-top: 10px;
}

.LoginSubmit {
    flex-grow: 1;
    max-width: 392px;
    border-radius: 20px;
    padding: 10px 16px;
    border: none;
    background-color: var(--green);
    color: white;
    font-weight: 600;
    margin-top: 20px;
}

.loginerror {
    font-size: 11px;
    margin: 0;
    color: #d22323;
    position: absolute;
    bottom: -16px;
    right: 10px;
    font-weight: 600;
}

.invalidUserError {
    font-size: 11px;
    margin: 0;
    color: #d22323;
    position: absolute;
    bottom: 76px;
    left: 36px;
    font-weight: 600;
}

.LoginPhoneDiv,
.LoginPwdDiv {
    position: relative;
}

.forgetPassBtn{
    border: none;
    background-color: transparent;
    color: var(--green);
    font-size: 11px;
    font-weight: 600;
}
.forgetPassBtn:hover{
    color: #d22323;
    text-decoration: underline;
}


@media only screen and (orientation: portrait) {
    .LoginPage {
        padding: 24px;
    }

    .LoginBox {
        padding: 24px;
        margin-top: 64px;
    }

    .loginHead {
        font-size: 24px;
    }

    .login_input {
        width: 200px;
    }
}