.requirements{
    position: fixed;
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    color: white;
    background-color: #164f55;
    align-items: center;
    margin-left: auto;
    top: 92.5vh;
    right: 1vh;
    border-radius: 10px 10px 0px 0px;
    border: 0px;

}
.requirements-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-container {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .SR_Inputs {
    display: flex;
    flex-direction: column;
  }
  
  .SR_input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .LC_btn {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .LC_btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .form-container {
      padding: 10px;
    }
  
    .SR_input {
      padding: 8px;
    }
  
    .LC_btn {
      padding: 8px;
    }
  }

.modal2 {
  position: fixed;
  z-index: 9;
  top: 15vh;
  right: 2vh;
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center
}

.modal-content2 {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
}



@media (max-width: 600px) {
  .modal-content {
    padding: 10px;
  }
}