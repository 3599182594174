.FeaturedCompanies{
    text-align: left;
    margin: 100px auto;
}

.FC_headingDiv{
    padding: 0 64px;
}

.FC_subhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FC_subhead>.subHead{
    text-align: left !important;
    justify-content: flex-start;
}

.FC_viewAllBtn{
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--orange);
}

.flexiBtnsDiv{
    display: flex;
    padding: 0 64px;
    gap: 16px;
    margin: 24px 0;
    overflow-x: scroll;
}

.flexiBtnsDiv::-webkit-scrollbar{display: none;}

.flexiBtns{
    padding: 6px 10px;
    border: 1px solid var(--green);
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    min-width: max-content;
}

.flexiBtns:hover{
    background-color: var(--green);
    color: white;
}

.FC_container{
    display: flex;
    gap: 32px;
    margin-top: 32px;
    padding: 0 64px;
    overflow-x: scroll;
    position: relative;
    min-height: 200px;
    /* justify-content: center; */
}

  .FC_container::-webkit-scrollbar{display: none;}


@media only screen and (orientation: portrait){
    .FC_headingDiv{
        padding: 0 24px;
    }

    .FC_container{
        padding: 0 24px;
    }

    .flexiBtnsDiv{
        padding: 0 24px;
    }
}